.hamburger {
  display: none;
  color: #4d39a3; /* #7563c9 */
}

.navigation a.hover {
  background-color: rgb(255, 255, 255);
  color: #4d39a3; /* #7563c9 */
  border-radius: 5px;
  padding: 3px 5px;
}
.navigation a.active {
  background-color: #4d39a3; /* #7563c9 */
  color: white;
  border-radius: 5px;
  padding: 3px 5px;
}
.navigation li:last-child {
  padding-right: 40px;
}


/* MOBILE DEVICES */
@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
    border: none;
    background-color: transparent;
    padding: 20px;
    font-size: 1.5rem;
    cursor: pointer;
    float: right;
  }

  .navigation-menu ul {
    display: none;
    min-height: 100vh;
    background:linear-gradient(0deg,#0d0823 1%,#0d0823 33%,#0f0f19);
  
  }

  .navigation-menu.expanded ul {
    display: block;
    position: absolute;
    top: 30px;
    left: -40px;
    flex-direction: column;
    width: 100%;
  }

  .navigation-menu.expanded li {
    text-align: center;
    margin: 0;
  }

  .navigation-menu.expanded li a {
    display: block;
    padding: 1rem;
  }
}
