html {
  background:linear-gradient(0deg,#0d0823 1%,#0d0823 33%,#0f0f19);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

a, a.visited {
  color: #7563c9;
  text-decoration: none;
}
a.hover, a.active {
  color:#5e4ab9;
  text-decoration: none;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* HOME PAGE */
.home {
  position:absolute;
  z-index: 0;
  color: #fff;
  width: 100%;
  padding-top: 100px;
}

.header-subtitle {
  font-size: 20px;
  line-height: 1.4em;
}

iframe {
  margin-top: 50px;
  height:100vh;
  border: none;
}

ul {
  margin: 0px;
}
li {
 display: inline;
 margin-left: 20px;
}

.gradient {
  background-image: linear-gradient(120deg,#ff7dff,#282876);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.app-container {
  width: 100%;
}

.branding,
.navigation {
  display: block;
  height: 75px;
  float: left;
  margin: 0;
  padding: 0;
}

.branding {
  position: absolute;
  z-index: 2;
  float: left;
  width: auto;
  padding: 0 20px;

}

.navigation { 
  float: right;
  position:absolute;
  z-index: 1;
  width: 100%;
  margin: 0;
  padding: 0;
  align-items: center;
}

nav {
  justify-content: center;
  font-size: 14px;
  padding-right: 20px;
}

nav ul {
  text-align: right;
  margin: 30px 0;
}
 
.button,
a.button {
  align-items: flex-start;
  background: #4d39a3; /*  #ff7dff; */
  color:#ffffff;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  font-weight: 500;
  overflow: hidden;
  padding: 12px;
  place-content: flex-start;
  position: relative;
  text-decoration: none;
  align-items: center;
  will-change: transform;
}

.cell {
  border: 2px #2e2166 solid;
  border-radius: 1em;
  background-color: #141432;
  max-width: 400px;
  padding: 5px 10px;
}

.left {
  padding: 0 5px;
  text-align: left;
  align-items: left;
}
